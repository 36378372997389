<template>
  <div class="mt-4">
    <div class="form--group" :class="{ 'has-error': validation.hasError('fullName') }">
      <div class="left--col">
        <label for="fullname">
          <span>{{ $t('profile.name') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col">
        <input id="fullname" v-model="fullName" class="basic--input" />
        <span class="val-error" v-if="validation.hasError('fullName')">{{
          validation.firstError('fullName')
        }}</span>
      </div>
    </div>
    <div class="form--group" :class="{ 'has-error': validation.hasError('ktp') }">
      <div class="left--col">
        <label for="ktp">
          <span>{{ $t('fields.identity_card_number') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col">
        <input id="ktp" v-model="ktp" class="basic--input" />
        <span class="val-error" v-if="validation.hasError('ktp')">{{
          validation.firstError('ktp')
        }}</span>
      </div>
    </div>
    <div class="form--group" :class="{ 'has-error': validation.hasError('phone') }">
      <div class="left--col">
        <label for="phone">
          <span>{{ $t('profile.phone') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col">
        <cleave
          id="phone"
          v-model="phone"
          :options="phoneCleaveOption"
          class="basic--input"
          type="text"
          name="phone"
        />
        <span class="val-error" v-if="validation.hasError('phone')">{{
          validation.firstError('phone')
        }}</span>
      </div>
    </div>
    <div class="form--group" :class="{ 'has-error': validation.hasError('address') }">
      <div class="left--col">
        <label for="address">
          <span>{{ $t('profile.address2') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col">
        <textarea id="address" v-model="address" class="basic--input" rows="3" />
        <span class="val-error" v-if="validation.hasError('address')">{{
          validation.firstError('address')
        }}</span>
      </div>
    </div>
    <div class="form--group" :class="{ 'has-error': validation.hasError('city') }">
      <div class="left--col">
        <label for="address">
          <span>{{ $t('profile.city') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col">
        <input id="city" v-model="city" class="basic--input" />
        <span class="val-error" v-if="validation.hasError('city')">{{
          validation.firstError('city')
        }}</span>
      </div>
    </div>

    <!--    <regional-form-->
    <!--      @loaded="regionalFormLoaded"-->
    <!--      ref="regionalForm"-->
    <!--      :village-required="true"-->
    <!--    ></regional-form>-->
  </div>
</template>

<script>
import { Validator } from 'simple-vue-validator';

import { mapState } from 'vuex';

import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.id.js';
// import Button from "../installment/button";
export default {
  name: 'billing-information',
  components: {
    // Button,
    Cleave,
  },
  data() {
    return {
      phoneCleaveOption: {
        phone: true,
        phoneRegionCode: 'id',
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.global.user,
      initRegionalData: (state) => state.checkout.billingInfo.initRegionalData,
    }),
    address: {
      get() {
        return this.$store.state.checkout.billingInfo.address;
      },
      set(val) {
        this.$store.commit('checkout/billingInfo/SET_ADDRESS', val);
      },
    },
    city: {
      get() {
        return this.$store.state.checkout.billingInfo.city;
      },
      set(val) {
        this.$store.commit('checkout/billingInfo/SET_CITY', val);
      },
    },
    fullName: {
      get() {
        return this.$store.state.checkout.billingInfo.fullName;
      },
      set(val) {
        this.$store.commit('checkout/billingInfo/SET_FULL_NAME', val);
      },
    },
    ktp: {
      get() {
        return this.$store.state.checkout.billingInfo.ktp;
      },
      set(val) {
        this.$store.commit('checkout/billingInfo/SET_KTP', val);
      },
    },
    phone: {
      get() {
        return this.$store.state.checkout.billingInfo.phone;
      },
      set(val) {
        this.$store.commit('checkout/billingInfo/SET_PHONE', val);
      },
    },
    loading: {
      get() {
        return this.$store.state.v2.booking.billingInfoLoading;
      },
      set(val) {
        this.$store.commit('v2/booking/SET_BILLING_INFO_LOADING', val);
      },
    },
  },
  validators: {
    fullName(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.name.required'))
        .maxLength(50, this.$i18n.t('errors.name.max', { maxChar: 50 }));
    },
    ktp(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.identity_card_number.required'))
        .maxLength(16, this.$i18n.t('errors.identity_card_number.max', { maxChar: 30 }));
    },
    phone(value) {
      return Validator.value(value).required(this.$i18n.t('errors.phone.required'));
    },
    address(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.address.required'))
        .maxLength(250, this.$i18n.t('errors.address.max', { maxChar: 250 }));
    },
    city(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.city.required'))
        .maxLength(250, this.$i18n.t('errors.city.max', { maxChar: 50 }));
    },
  },
  methods: {
    validate() {
      let validationArray = [this.$validate()];
      return Promise.all(validationArray).then(function (results) {
        return (
          results.filter(function (result) {
            return !result;
          }).length === 0
        );
      });
    },
  },
};
</script>

<style scoped></style>
